import Writing from "./Writing";

function WritingGallery() {
  return (
    <div className="App">
      <Writing />
    </div>
  );
}

export default WritingGallery;
