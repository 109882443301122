import "./Footer.css";

function Footer() {
  return (
    <div className="footerWrap">
      <div className="ercFooterTitle">
        <h2 className="logo">ERC</h2>
      </div>
    </div>
  );
}

export default Footer;
