import Drawing from "./Drawing";

function DrawingGallery() {
  return (
    <div className="App">
      <Drawing />
    </div>
  );
}

export default DrawingGallery;
